import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  reduxForm,
  Field,
  change,
  Form,
  formValueSelector,
  getFormValues,
  submit
} from 'redux-form'

import { getUsers } from '../../store/users/actions'
import heic2any from 'heic2any'
import Tooltip from '@material-ui/core/Tooltip'
import ToggleButton from '@material-ui/lab/ToggleButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Label from '../label/Label'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import { Box } from './styles/CreateModuleForm.style'
import Button from '../button/Button'
import {
  required,
  validEmail,
  capitalize,
  validZipcode,
  normalizeCEP,
  normalizePhone,
  normalizeCPF,
  zipcodeUrl,
  validPhone,
  validFullname,
  isValidCPF
} from '../../utils/formUtils'
import StyledSVG from '../shared/StyledSVG'
import ListImg from '../shared/ListImg'
import imgSVG from '../../assets/icons/img.svg'
import { handleAddAvatar } from '../../utils/helpers'
import { showAlertMessage } from '../../store/alert/actions'
import { occupationsIds } from '../../utils/constants'
import { backToThePage } from '../../store/pagination/actions'
import Modal from '../modal/Modal'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    margin: 0,
    borderRadius: '8px',
    borderTopLeftRadius: 'unser',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    paddingLeft: '1.5rem',
    '& .select__value-container--is-multi > .select__input > input': {
      height: 38
    }
  },
  btnStyle: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10
  },
  bull: {
    color: theme.palette.primary.dark,
    fontSize: '2rem',
    verticalAlign: 'text-bottom',
    marginLeft: '0.8rem'
  },
  defineAsContact: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-body1': {
      fontSize: 'unset'
    }
  },
  radioValue: {
    '& span:nth-of-type(2)': {
      fontSize: 'unset !important'
    }
  },
  fieldLabel: {
    color: '#6E6E6E',
    fontWeight: '600'
  },
  sectionLabel: {
    margin: 0,
    color: '#555555',
    weight: '700',
    lineHeight: '24px',
    fontSize: '16px'
  },
  moments: {
    '& div': {
      justifyContent: 'flex-start'
    },
    '& .MuiGrid-container': {
      alignItems: 'center'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  saveBtn: {
    backgroundColor: '#386093',
    width: 165,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  searchBtn: {
    marginLeft: 12,
    width: 146,
    border: '1px solid #00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#00ACDB',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  btnsModal: {
    display: 'flex',
    gap: 12,
    justifyContent: 'flex-end'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 169,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  addAvatarBtn: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '& button': {
      backgroundColor: '#F6F7F8',
      border: 'none',
      borderRadius: '50% !important',
      marginRight: 16,
      padding: 7
    },
    '& button.Mui-selected': {
      backgroundColor: '#F6F7F8'
    },
    '& > div.MuiToggleButtonGroup-root': {
      marginBottom: '0px !important'
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  }
}))

const CreateCoordinatorForm = ({
  alert,
  change,
  handleSubmit,
  initialValues,
  pristine,
  onSubmit,
  submitSucceeded,
  touch,
  zipCodeValue,
  valid,
  isVersarAdmin,
  schoolValue,
  school,
  fieldValues,
  backToThePage,
  currentPagination,
  currentCoordinator,
  isEdit,
  getUsers
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [uploadingInProgress, setUploadingInProgress] = React.useState(false)

  const imageRef = React.useRef(null)

  const goBack = () => {
    backToThePage({ ...currentPagination, canGoBack: true })
    history.push('/settings/coordinator')
  }

  const [imagesElements, setImagesElements] = React.useState({ img: null })

  const [userAlreadyRegistered, setUserAlreadyResgistered] = React.useState(
    null
  )
  const [
    userAlreadyRegisteredModal,
    setUserAlreadyRegisteredModal
  ] = React.useState({
    email: false,
    taxpayer_number: false,
    open: false
  })

  const searchExistingUser = async () => {
    await getUsers({
      params: {
        'q[taxpayer_number_eq]': fieldValues.taxpayer_number,
        'q[email_eq]': fieldValues.email,
        'q[m]': 'or'
      }
    }).then(res => {
      setUserAlreadyResgistered(res)
    })
  }

  submitSucceeded && goBack()

  React.useEffect(
    async () => {
      if (userAlreadyRegistered) {
        if (userAlreadyRegistered.length > 0) {
          const newState = { ...userAlreadyRegisteredModal, open: true }
          if (
            String(userAlreadyRegistered[0].email) === String(fieldValues.email)
          ) {
            newState.email = true
          }
          if (
            String(userAlreadyRegistered[0].taxpayer_number) ===
            String(fieldValues.taxpayer_number)
          ) {
            newState.taxpayer_number = true
          }
          if (
            String(userAlreadyRegistered[0].taxpayer_number) ===
              String(fieldValues.taxpayer_number) &&
            String(userAlreadyRegistered[0].email) === String(fieldValues.email)
          ) {
            await change('userToEdit', userAlreadyRegistered[0])
            dispatch(submit('createCoordinatorForm'))
          } else {
            setUserAlreadyRegisteredModal(newState)
          }
        } else {
          dispatch(submit('createCoordinatorForm'))
        }
      }
    },
    [userAlreadyRegistered]
  )

  const handleChange = (event, input) => {
    let newValue
    if (Array.isArray(event)) {
      if (input.name === 'classroom_coordinators_attributes') {
        newValue = event?.map(v => ({ classroom_id: v.value }))
      } else {
        newValue = event?.map(v => ({
          subject_id: v.value,
          school_id: school.school_id
        }))
      }
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleDeleteAvatar = (deleteFromDataBase = false) => {
    setImagesElements(prevState => {
      const newState = {
        img: deleteFromDataBase === true ? 'delete' : null
      }
      change('image', newState.img)
      return newState
    })
  }

  const handleOnChangeAvatar = event => {
    handleAddAvatar(
      event,
      setUploadingInProgress,
      alert,
      param =>
        setImagesElements(prevState => {
          const newState = {
            img: param
          }
          change('image', newState.img)
          return newState
        }),
      heic2any
    )
  }

  const searchZipcopde = zipcode => {
    let newZipcode = zipcode?.replace(/[^0-9]/g, '')
    if (newZipcode?.length !== 8) {
      alert('O cep deve possuir 8 números', 'info')
      return
    }
    fetch(zipcodeUrl(newZipcode))
      .then(res => res.json())
      .then(data => {
        if (data.erro) {
          alert(
            `O cep ${zipcode} não foi encontrado. Digite o endereço manualmente`,
            'warning'
          )
        } else {
          change('address.street', data.logradouro)
          change('address.neighbourhood', data.bairro)
        }
      })
      .catch(error => console.error(error))
  }

  const RequiredFields = (
    <>
      <FormControlLabel
        className={classes.radioValue}
        value={'true'}
        control={<Radio color='primary' />}
        label='Sim'
      />
      <FormControlLabel
        className={classes.radioValue}
        value={'false'}
        control={<Radio color='primary' />}
        label='Não'
      />
    </>
  )
  const contactIsFilled = () => {
    if (fieldValues?.contact_from_school !== undefined) {
      return (
        fieldValues?.contact_from_school.toString() === 'true' ||
        fieldValues?.contact_from_school.toString() === 'false'
      )
    }
    return false
  }

  return (
    <div className={`${classes.root}`}>
      <Box>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Label>Cadastro de novo coordenador</Label>
              <p style={{ maxWidth: '350px' }}>
                Cadastre novos coordenadores de maneira ágil e melhore a gestão
                educacional em sua escola.
              </p>
            </Grid>
            {isVersarAdmin && (
              <>
                <Grid item xs={12} sm={5}>
                  <label htmlFor='school_id'>
                    Escola
                    <span className={classes.bull}>&bull;</span>
                  </label>
                  <Field
                    component={AsyncSelect}
                    id='school_id'
                    name='school_id'
                    disabled={isEdit}
                    defaultValue={initialValues.school_id}
                    placeholder='Digite ou selecione uma escola'
                    handleAsyncChange={handleChange}
                    searchParam='q[name_cont]'
                    cacheUniqs={0}
                    request={{
                      path: 'school'
                    }}
                    touch={touch}
                    validate={[required]}
                  />
                </Grid>
                <Grid item sm={6} />
              </>
            )}
            <Grid item xs={12} sm={12}>
              <label htmlFor='name' className={classes.fieldLabel}>
                Nome
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={MaterialInput}
                name='name'
                placeholder='Nome do coodenador'
                type='text'
                autoComplete='off'
                normalize={capitalize}
                validate={[required, validFullname]}
                autoFocus
              />
            </Grid>
            <Grid className={classes.fieldLabel} item xs={12} sm={4}>
              <label htmlFor={`taxpayer_number`}>
                CPF
                <span
                  className={classes.bull}
                  style={{
                    fontSize: '2rem',
                    verticalAlign: 'text-bottom',
                    marginLeft: '0.8rem'
                  }}
                >
                  &bull;
                </span>
              </label>
              <Field
                allowClear
                component={MaterialInput}
                name={`taxpayer_number`}
                placeholder='CPF'
                type='text'
                autoComplete='off'
                normalize={normalizeCPF}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
                validate={[isValidCPF]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label
                className={classes.fieldLabel}
                htmlFor='classroom_coordinators_attributes'
              >
                Turma(s)
              </label>
              <Field
                component={AsyncSelect}
                defaultValue={initialValues.classroom_coordinators_attributes}
                id='classroom_coordinators_attributes'
                name='classroom_coordinators_attributes'
                isMulti
                placeholder='Selecione a(s) turma(s)'
                handleAsyncChange={handleChange}
                cacheUniqs={[schoolValue]}
                searchParam={'q[name_cont]'}
                request={{
                  path: 'classroom',
                  params: {
                    'q[school_id_eq]':
                      (school && school.school_id) ||
                      schoolValue?.value ||
                      schoolValue,
                    'q[year_lteq]': new Date().getFullYear() + 1,
                    'q[year_gteq]': new Date().getFullYear()
                  }
                }}
                touch={touch}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={classes.fieldLabel} htmlFor='email'>
                Email
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={MaterialInput}
                name='email'
                placeholder='Digite um email'
                type='email'
                autoComplete='off'
                disabled={!!currentCoordinator?.id}
                validate={[required, validEmail]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={classes.fieldLabel} htmlFor='phone'>
                Telefone
              </label>
              <Field
                component={MaterialInput}
                name='phone'
                placeholder='Telefone de contato'
                type='text'
                autoComplete='off'
                normalize={normalizePhone}
                validate={[validPhone]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
              <div className={classes.defineAsContact}>
                <label className={classes.fieldLabel} htmlFor='contact'>
                  Deseja cadastrar como um contato da escola?
                  <span className={classes.bull}>&bull;</span>
                </label>
                <RadioGroup
                  row
                  aria-label={'Adição de contato'}
                  name='contact_from_school'
                  defaultValue={`${initialValues?.contact_from_school}`}
                  onChange={event =>
                    change('contact_from_school', event.target.value)
                  }
                >
                  {RequiredFields}
                </RadioGroup>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.addAvatarBtn}>
                <p>Adicionar foto:</p>
                <input
                  accept='.png, .jpg, .jpeg, .heic'
                  hidden
                  name='files'
                  onChange={handleOnChangeAvatar}
                  ref={imageRef}
                  type='file'
                />
                {!uploadingInProgress ? (
                  <Tooltip title='Adicionar foto' aria-label='foto'>
                    <ToggleButton
                      style={{ background: 'none' }}
                      onClick={() =>
                        imageRef.current && imageRef.current.click()
                      }
                      value='image'
                      aria-label='image'
                      color='secondary'
                    >
                      <StyledSVG width={21} height={21} src={imgSVG} />
                    </ToggleButton>
                  </Tooltip>
                ) : (
                  <CircularProgress color='secondary' size={21} />
                )}
              </div>
              {imagesElements.img ? (
                // show image in createTeacher
                <ListImg
                  handleDeleteImg={handleDeleteAvatar}
                  item={imagesElements.img}
                />
              ) : initialValues.avatar_url ? (
                // show image in editUser
                <ListImg
                  item={initialValues.avatar_url}
                  handleDeleteImg={handleDeleteAvatar}
                />
              ) : (
                undefined
              )}
            </Grid>
            <Grid item xs={12}>
              <h4 className={classes.sectionLabel}>Endereço</h4>
            </Grid>
            <Grid item xs={6} md={3} sm={6}>
              <label className={classes.fieldLabel} htmlFor='address.zipcode'>
                CEP
              </label>
              <Field
                component={MaterialInput}
                name='address.zipcode'
                placeholder='CEP'
                type='text'
                autoComplete='off'
                normalize={normalizeCEP}
                validate={[validZipcode]}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    searchZipcopde(zipCodeValue)
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} style={{ alignSelf: 'center' }}>
              <Button
                type='button'
                className={classes.searchBtn}
                variant='outlined'
                onClick={() => searchZipcopde(zipCodeValue)}
                style={{ marginTop: 20 }}
              >
                Procurar
              </Button>
            </Grid>
            <Grid item xs={6} md={5} sm={6}>
              <label className={classes.fieldLabel} htmlFor='address.street'>
                Rua
              </label>
              <Field
                component={MaterialInput}
                name='address.street'
                placeholder='Nome da rua'
                type='text'
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={6} md={2} sm={4}>
              <label className={classes.fieldLabel} htmlFor='address.number'>
                Número
              </label>
              <Field
                component={MaterialInput}
                name='address.number'
                placeholder='Número'
                type='text'
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={6} md={4} sm={5}>
              <label
                className={classes.fieldLabel}
                htmlFor='address.complement'
              >
                Complemento
              </label>
              <Field
                component={MaterialInput}
                name='address.complement'
                placeholder='Complemento'
                type='text'
                autoComplete='off'
              />
            </Grid>
            <Grid className={classes.fieldLabel} item xs={6} sm={5}>
              <label htmlFor='address.neighbourhood'>Bairro</label>
              <Field
                component={MaterialInput}
                name='address.neighbourhood'
                placeholder='Bairro'
                type='text'
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={12} className={classes.btnStyle}>
              <Button
                className={classes.backBtn}
                variant='outlined'
                type='button'
                onClick={() => goBack()}
              >
                Voltar
              </Button>
              <Button
                className={classes.saveBtn}
                onClick={() => {
                  searchExistingUser()
                }}
                disabled={
                  !valid ||
                  !contactIsFilled() ||
                  (currentCoordinator && currentCoordinator.user_id
                    ? false
                    : pristine)
                }
              >
                {currentCoordinator && currentCoordinator.user_id
                  ? 'Salvar'
                  : 'Cadastrar'}
              </Button>
            </Grid>
            <Modal
              open={userAlreadyRegisteredModal.open}
              handleClose={() =>
                setUserAlreadyRegisteredModal(prev => ({
                  ...prev,
                  open: false
                }))
              }
            >
              <div>
                {((userAlreadyRegisteredModal.taxpayer_number &&
                  !userAlreadyRegisteredModal.email) ||
                  (userAlreadyRegisteredModal.email &&
                    !userAlreadyRegisteredModal.taxpayer_number)) && (
                    <h1>
                      {`O usuário possui cadastro ativo com o CPF "${
                      userAlreadyRegistered[0].taxpayer_number
                    }" e e-mail "${userAlreadyRegistered[0].email}"`}
                    </h1>
                )}
                {userAlreadyRegisteredModal.taxpayer_number &&
                  !userAlreadyRegisteredModal.email && (
                    <h2>{`O e-mail "${
                      fieldValues.email
                    }" não será considerado`}</h2>
                  )}
                {userAlreadyRegisteredModal.email &&
                  !userAlreadyRegisteredModal.taxpayer_number && (
                    <h2>
                      {`O CPF "${userAlreadyRegistered[0].taxpayer_number}" será
                      substituido pelo "${fieldValues.taxpayer_number}"`}
                    </h2>
                  )}
              </div>
              <div className={classes.btnsModal}>
                <Button
                  className={classes.cancelBtn}
                  onClick={() => {
                    setUserAlreadyRegisteredModal(prev => ({
                      ...prev,
                      open: false
                    }))
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  className={classes.sendBtn}
                  onClick={async () => {
                    await change('userToEdit', userAlreadyRegistered[0])
                    dispatch(submit('createCoordinatorForm'))
                  }}
                >
                  Continuar
                </Button>
              </div>
            </Modal>
          </Grid>
        </Form>
      </Box>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createCoordinatorForm')

  const initValues = {
    name: props.currentCoordinator?.coordinator?.name,
    email: props.currentCoordinator?.coordinator?.email,
    phone: props.currentCoordinator?.coordinator?.phone,
    avatar_url: props.currentCoordinator?.coordinator?.avatar_url,
    address: props.currentCoordinator?.coordinator?.address,
    contact_from_school:
      props.currentCoordinator?.coordinator?.contact_from_school,
    taxpayer_number: props.currentCoordinator?.coordinator?.taxpayer_number,
    school_id: props.currentCoordinator?.school && {
      label: props.currentCoordinator.school.name,
      value: props.currentCoordinator.school_id
    },
    classroom_coordinators_attributes: props?.currentCoordinator?.coordinator
      ?.classrooms
      ? props.currentCoordinator.coordinator.classrooms.map(classroom => ({
          label: classroom?.name,
          value: classroom.id
        }))
      : []
  }
  return {
    initialValues: initValues,
    zipCodeValue: selector(state, 'address.zipcode'),
    schoolValue: selector(state, 'school_id'),
    fieldValues: getFormValues('createCoordinatorForm')(state),
    currentPagination: state.pagination
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) =>
    dispatch(change('createCoordinatorForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  backToThePage: data => dispatch(backToThePage(data)),
  getUsers: data => dispatch(getUsers.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createCoordinatorForm',
    onSubmit: async (values, dispatch, props) => {
      const {
        // eslint-disable-next-line camelcase
        contact_from_school,
        userToEdit
      } = values
      // eslint-disable-next-line camelcase
      delete values.school_id
      delete values.userToEdit
      delete values.contact_from_school
      if (props.currentCoordinator?.coordinator?.id) {
        const addClassroomCoordinator = []
        const findClassroom = id =>
          props.currentCoordinator.coordinator.classrooms.find(
            classroom => String(classroom.id) === String(id)
          )
        // get only new classroom_ids
        values.classroom_coordinators_attributes.forEach(classroom => {
          if (classroom.classroom_id) {
            const coordinatorClassroom = findClassroom(classroom.classroom_id)
            !coordinatorClassroom &&
              addClassroomCoordinator.push({
                classroom_id: classroom.classroom_id
              })
          }
        })
        // classrooms to remove from coordinator
        const removeClassroomCoordinator = props.currentCoordinator.coordinator.classroom_coordinators
          .filter(classroom => {
            const find = values.classroom_coordinators_attributes.some(
              c =>
                String(c.classroom_id) === String(classroom.classroom_id) ||
                String(c.value) === String(classroom.classroom_id)
            )
            return !find && classroom.id
          })
          .map(classroomCoordinator => classroomCoordinator.id)

        props.editCoordinator({
          ...values,
          id: props.currentCoordinator.coordinator.id,
          removeClassroomCoordinator: removeClassroomCoordinator,
          addClassroomCoordinator: addClassroomCoordinator
        })
      } else {
        props.createCoordinator({
          ...values,
          userToEdit,
          user_occupations_attributes: [
            { occupation_id: occupationsIds['COORDINATOR'] }
          ],
          contact_from_school: contact_from_school.toString() === 'true',
          school_coordinators_attributes: [
            { school_id: props?.schoolValue || props?.school?.school_id }
          ]
        })
      }
    }
  })(CreateCoordinatorForm)
)
