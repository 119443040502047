import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import CreateCoordinatorForm from '../components/form/CreateCoordinatorForm'
import { CircularProgress } from '@material-ui/core'
import { roles } from '../utils/constants'
import {
  getCoordinatorById,
  createCoordinator,
  editCoordinator
} from '../store/coordinators/actions'

const mapStateToProps = ({ auth, school, coordinators }) => {
  return {
    userOccupations: auth.currentOccupation,
    currentSchool: school.currentSchool,
    currentCoordinator: coordinators.currentItem,
    coordinatorIsFetching: coordinators.isFetching
  }
}

const mapDispatchToProps = {
  getCoordinatorById,
  createCoordinator,
  editCoordinator
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0'
  }
}))

const CreateCoordinatorPage = ({
  currentSchool,
  createCoordinator,
  editCoordinator,
  isFetching,
  match,
  userOccupations,
  currentCoordinator,
  getCoordinatorById,
  coordinatorIsFetching
}) => {
  const classes = useStyles()
  const { coordinatorId } = match.params
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  useEffect(
    () => {
      const loadData = async () => {
        await getCoordinatorById(coordinatorId)
      }
      coordinatorId && loadData()
    },
    [coordinatorId]
  )

  if (coordinatorIsFetching) {
    return <CircularProgress color='secondary' />
  }

  return (
    <div className={classes.root}>
      <CreateCoordinatorForm
        isEdit={!!coordinatorId}
        school={currentSchool}
        createCoordinator={createCoordinator}
        editCoordinator={editCoordinator}
        currentCoordinator={currentCoordinator}
        isVersarAdmin={isVersarAdmin}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  CreateCoordinatorPage
)
