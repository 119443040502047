import React from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import SearchReactivateCoordinatorForm from '../components/form/SearchReactivateCoordinatorForm'
import { getDeletedCoordinators } from '../store/coordinators/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school, classrooms, coordinators }) => {
  return {
    deletedCoordinators: coordinators.deletedCoordinators.items,
    deletedCoordinatorsPagination: coordinators.deletedCoordinators.pagination,
    userOccupations: auth.currentOccupation,
    currentTeacher: coordinators.currentItem,
    teacherIsFetching: coordinators.isFetching,
    currentSchool: school.currentSchool,
    classrooms: classrooms,
    isFetching: coordinators.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  getDeletedCoordinators: data => dispatch(getDeletedCoordinators(data))
})

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0'
    // maxWidth: 1220
  }
}))

const CreateTeacherPage = ({
  currentSchool,
  userOccupations,
  getDeletedCoordinators,
  deletedCoordinators,
  deletedCoordinatorsPagination,
  isFetching
}) => {
  const classes = useStyles()
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  return (
    <div className={classes.root}>
      <SearchReactivateCoordinatorForm
        school={currentSchool}
        isVersarAdmin={isVersarAdmin}
        deletedCoordinators={deletedCoordinators}
        getDeletedCoordinators={getDeletedCoordinators}
        isFetching={isFetching}
        isAdmin={isAdmin}
        pagination={deletedCoordinatorsPagination}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeacherPage)
