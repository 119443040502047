import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import Label from '../components/label/Label'
import { roles } from '../utils/constants'

import {
  getSchoolContentCreatorsById,
  getSchoolAdministratorById,
  getSchoolTeacherById
} from '../store/school/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2rem 0'
  },
  container: {
    display: 'grid',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    }
  },
  listItem: {
    alignItems: 'center',
    borderRadius: '8px',
    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)',
    backgroundColor: '#386093',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-around',
    height: '5rem',
    maxWidth: '320px',
    margin: '1rem',
    textDecoration: 'none'
  }
}))

const SettingsPage = ({
  getSchoolContentCreatorsById,
  getSchoolAdministratorById,
  getSchoolTeacherById,
  user,
  userOccupations
}) => {
  const classes = useStyles()
  const isAdminVersar = userOccupations.includes(roles.VERSAR_ADMIN)
  const isSchoolAdmin = userOccupations.includes(roles.SCHOOL_ADMIN)
  const isCoordinator = userOccupations.includes(roles.COORDINATOR)
  const BERTONI_APP_ID = process.env.REACT_APP_PROJECT_ID === '42'

  useEffect(
    () => {
      userOccupations.includes(roles.CONTENT_CREATOR) &&
        getSchoolContentCreatorsById(user.id)
      userOccupations.includes(roles.SCHOOL_ADMIN) &&
        getSchoolAdministratorById({ id: user.id, isLogin: false })
      userOccupations.includes(roles.TEACHER) &&
        getSchoolTeacherById({ id: user.id, isLogin: false })
    },
    [userOccupations]
  )

  const listOfOptions = [
    {
      key: 'classrooms',
      component: (
        <ListItem
          className={classes.listItem}
          component={NavLink}
          to='/settings/classrooms'
        >
          <h3>Turmas e alunos</h3>
        </ListItem>
      ),
      permission: isAdminVersar || isSchoolAdmin || isCoordinator
    },
    {
      key: 'teachers',
      component: (
        <ListItem
          className={classes.listItem}
          component={NavLink}
          to='/settings/teachers'
        >
          <h3>Professores</h3>
        </ListItem>
      ),
      permission: isAdminVersar || isSchoolAdmin || isCoordinator
    },
    {
      key: 'layout',
      component: (
        <ListItem
          className={classes.listItem}
          component={NavLink}
          to='/settings/layout'
        >
          <h3>Layout</h3>
        </ListItem>
      ),
      permission: isAdminVersar || isSchoolAdmin
    },
    {
      key: 'userConfig',
      component: (
        <ListItem
          className={classes.listItem}
          component={NavLink}
          to='/settings/historic'
        >
          <h3>Configurações de usuários</h3>
        </ListItem>
      ),
      permission: isAdminVersar || isSchoolAdmin
    },
    {
      key: 'covers',
      component: (
        <ListItem
          className={classes.listItem}
          component={NavLink}
          to='/settings/covers'
        >
          <h3>Capas</h3>
        </ListItem>
      ),
      permission: isAdminVersar
    },
    {
      key: 'coordinators',
      component: (
        <ListItem
          className={classes.listItem}
          component={NavLink}
          to='/settings/coordinator'
        >
          <h3>Coordenadores</h3>
        </ListItem>
      ),
      permission: isAdminVersar || isSchoolAdmin || isCoordinator
    },
    {
      key: 'moderation',
      component: (
        <ListItem
          className={classes.listItem}
          component={NavLink}
          to='/settings/moderation'
        >
          <h3>Moderação</h3>
        </ListItem>
      ),
      permission: isAdminVersar || isSchoolAdmin
    }
  ]

  return (
    <div className={classes.root}>
      <Label>Configurações</Label>
      {(isAdminVersar || isSchoolAdmin || isCoordinator) && (
        <List className={classes.container}>
          {listOfOptions
            .filter(item => {
              if (
                (!BERTONI_APP_ID ||
                  (BERTONI_APP_ID && item.key !== 'covers')) &&
                item.permission === true
              ) {
                return true
              }
            })
            .map(item => (
              <React.Fragment key={item.key}>{item.component}</React.Fragment>
            ))}
        </List>
      )}
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user?.data,
    userOccupations: auth.currentOccupation
  }
}

const mapDispatchToProps = {
  getSchoolContentCreatorsById,
  getSchoolAdministratorById,
  getSchoolTeacherById
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
